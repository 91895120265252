<template>
  <div>
    <QuienesSomosS1/>
  </div>
</template>
<script>
// @ is an alias to /src
import QuienesSomosS1 from '@/components/QuienesSomos/QuienesSomosS1.vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'QuienesSomos',
  components: {
    QuienesSomosS1
  },
  setup() {
    useMeta({
      title:'Quienes somos | Ibancar',
      meta: [
        {name:'robots', content:'noindex'},
        {name:'description', content:'Ibancar es la única empresa que te presta dinero sin empeñar tu vehículo y sin cambiar la titularidad.Tienes un auto, tienes un préstamo '},
        {name:'keywords', content:'Préstamos por tu auto, empeña tu auto sin dejarlo, préstamos por auto, préstamos sin importar buro de credito, préstamos personales, prestamos inmediatos, credito aval auto'}
      ],
      link: [
        { href: 'https://ibancar.com/quienes-somos', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/quienes-somos', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
}
</script>